export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">Aktualizovaná sekce Bonusy a akce</p>
      <p className="new__description">
        Na mapu byla přidána poloha nejbližší pobočky, bankomatu nebo terminálu.
      </p>
      <p className="new__description">
        Přidána podpora pro Face ID a Touch ID.
      </p>
      <p className="new__description">
        Opraven problém s oznámením push, se kterým se setkávali někteří
        uživatelé Androidu 10.
      </p>
      <p className="new__description">
        Opravili jsme problém, který způsoboval, že text v nastavení byl pro
        uživatele Androidu velmi malý.
      </p>
    </div>
  );
};
